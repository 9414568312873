.question {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.questionCounter {
  font-size: 3vh;
}

.currentQuestion {
  font-size: 2vh;
  text-align: center;
}

.singleQuestion {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  padding: 20px;
}

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 99;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
}

.singleOption {
  width: 50vw;
  height: auto;
  padding: 15px 20px;
  margin: 10px;
  box-shadow: 0 0 2px black;
  background-color: #00000022;
}

.controls {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: -100px;
  z-index: 0;
}

.select {
  background-color: rgb(7, 207, 0);
  color: rgba(255, 255, 255, 0.66);
  box-shadow: 0 0 1px black;
}

.wrong {
  background-color: rgb(233, 0, 0);
  color: rgba(255, 255, 255, 0.66);
  box-shadow: 0 0 1px black;
}

.buttoncontainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.small-button {
  width: 94px;
  height: 89px;
  position: absolute;
  background: url('https://www.myinstants.com/media/images/transparent_button_small_normal.png.pagespeed.ce.Q_pq0WnHY3.png')
    no-repeat;
  border: 0;
  display: block;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  font-size: 30px;
  padding-bottom: 20px;
}

.small-button:focus {
  background: url('https://www.myinstants.com/media/images/transparent_button_small_normal.png.pagespeed.ce.Q_pq0WnHY3.png')
    no-repeat;
}
.small-button:active {
  background: url('https://www.myinstants.com/media/images/transparent_button_small_pressed.png.pagespeed.ce.ApeRPoBIvd.png')
    no-repeat;
}
.small-button-background-quit {
  width: 86px;
  height: 84px;
  margin-top: 3px;
  margin-left: 3px;
  position: absolute;
  background-color: red;
  border-radius: 50%;
}

.small-button-background-next {
  width: 86px;
  height: 84px;
  margin-top: 3px;
  margin-left: 3px;
  position: absolute;
  background-color: blue;
  border-radius: 50%;
}
.small-button-shadow {
  width: 94px;
  height: 89px;
  margin-bottom: 5px;
  background: url('https://www.myinstants.com/media/images/transparent_button_small_shadow.png.pagespeed.ce.7foh4e-miG.png')
    no-repeat;
}

@media (max-width: 380px) {
  .singleOption {
    padding: 5px 20px;
  }
}
