.footer {
  font-size: 3vh;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  margin-top: -10px;
  line-height: 1;
  text-decoration: none;
  color: #fff;
  font-family: Iceland;
}

.footer:hover {
  transform: scaleX(-1);
  transition: 1s;
}

.footer:nth-child(1) {
  font-family: Iceland;
  animation: neon2 1.5s ease-in-out infinite alternate;
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
      0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
}
