.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: -10px;
}

.subtitle {
  font-size: 3vh;
  padding: 20px 0 10px 0;
}

.quizInfo {
  font-size: 2vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  margin: 10px;
}
