.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  text-align: center;
}

.score {
  text-transform: uppercase;
  font-size: 9vh;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1;
  text-decoration: none;
  color: #fff;
}

.score:nth-child(1) {
  font-family: 'Audiowide', cursive;
  animation: neon5 1.5s ease-in-out infinite alternate;
}

.buttoncontainer {
  margin-top: 100px;
}

.small-button-background-restart {
  width: 86px;
  height: 84px;
  margin-top: 3px;
  margin-left: 3px;
  position: absolute;
  background-color: #000;
  border-radius: 50%;
}

@keyframes neon5 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff9900,
      0 0 70px #ff9900, 0 0 80px #ff9900, 0 0 100px #ff9900, 0 0 150px #ff9900;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff9900,
      0 0 35px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff9900;
  }
}
